
export const numberArray = {
    0: [32, 15],
    1: [7, 20],
    2: [28, 9],
    3: [26, 0],
    4: [19, 21],
    5: [17, 22],
    6: [34, 27],
    7: [29, 28],
    8: [12, 19],
    9: [16, 2],
    10: [35, 23],
    11: [5, 24],
    12: [3, 35],
    13: [1, 27],
    14: [4, 32],
    15: [20, 0],
    16: [9, 7],
    17: [22, 5],
    18: [18, 29],
    19: [21, 8],
    20: [1, 15],
    21: [4, 19],
    22: [17, 5],
    23: [10, 35],
    24: [11, 36],
    25: [30, 23],
    26: [36, 3],
    27: [34, 13],
    28: [2, 7],
    29: [18, 7],
    30: [25, 25],
    31: [33, 0],
    32: [35, 14],
    33: [31, 14],
    34: [6, 27],
    35: [23, 10],
    36: [24, 26]
};
